:root {
	--green: #35f575;
	--cyan: #6ceef1;
	--blue: #348ff2;
}

.ekg {
	width: 100%;
	display: grid;
	position: relative;
	margin: 0;
	grid-gap: .25rem;
	border-bottom: .25rem solid #aaa;
}

.ekg:before,
.ekg:after {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: .25rem;
	background: #aaa;
}

.ekg:before {
	top: 17rem;
}

.ekg:after {
	top: 34.25rem;
}

/** scan lines **/
/* .ekg:after {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.2) 80%);
	background-size: .5rem .3rem;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	display: block;
} */

.graph {
	position: relative;
	padding: 0;
	animation: travel 6s infinite linear;
	display: flex;
	background-size: 100% auto;
	background-position: -38rem 0;
	height: 17rem;
	flex-basis: 83.3333%;
	max-width: 83.3333%;
}

.graph--hr {
	background: linear-gradient(90deg, var(--charcoal) 0, var(--green) 50%, var(--charcoal) 50%);
}

.graph--o2 {
	background: linear-gradient(90deg, var(--charcoal) 0, var(--cyan) 50%, var(--charcoal) 50%);
}

.graph--rp {
	background: linear-gradient(90deg, var(--charcoal) 0, var(--blue) 50%, var(--charcoal) 50%);
}

.block {
	margin: 0;
	display: flex;
	width: 16.7%;
	height: 100%;
	background-size: 850% auto;
	animation: shuffle 36s steps(1) infinite;
}

.block:first-child {
	background-position: 0, 0;
	animation-delay: -2s;
}

.block:nth-child(2n) {
	background-position: 16.6%, 0;
	animation-delay: -13s;
}

.block:nth-child(3n) {
	background-position: 33.3%, 0;
	animation-delay: -30s;
}

.block:nth-child(4n) {
	background-position: 50%, 0;
	animation-delay: -5s;
}

.block:nth-child(5n) {
	background-position: 66.6%, 0;
	animation-delay: -22s;
}

.block:last-child {
	background-position: 83.3%, 0;
	animation-delay: -9s;
}

.heartbeat {
	color: var(--green);
	position: absolute;
	top: 1rem;
	right: 5rem;
	opacity: 1;
	animation: beat 1s infinite;
}

.graph-label {
	font-weight: var(--bold);
	font-size: 2rem;
	color: var(--white);
	position: absolute;
	transform: rotate(-90deg);
	right: -7rem;
	top: 6.75rem;
	width: 17rem;
	padding: .5rem  1rem;
	text-align: center;
	text-shadow: 0 0 3px var(--charcoal);
}

.graph--hr .graph-label {
	background: var(--green);
}

.graph--o2 .graph-label {
	background: var(--cyan);
}

.graph--rp .graph-label {
	background: var(--blue);
}

.graph-value {
	font-weight: var(--bold);
	font-size: 3.5rem;
	position: absolute;
	left: 100%;
	top: 50%;
	text-align: center;
	line-height: 1;
	transform: translate(2rem, -65%);
	text-transform: uppercase;
}

.graph--hr .graph-value {
	color: var(--green);
}

.graph--o2 .graph-value {
	color: var(--cyan);
}

.graph--rp .graph-value {
	color: var(--blue);
}

.status {
	position: absolute;
	color: var(--green);
	font-weight: var(--bold);
	bottom: 1rem;
	left: 1rem;
	text-transform: uppercase;
}

.status span {
	color: var(--white);
	opacity: .5;
}

@keyframes shuffle {
	0% {
		background-position: 83.3%, 0;
	}
	16.6% {
		background-position: 33.33%, 0;
	}
	33.3% {
		background-position: 66.66%, 0;
	}
	50% {
		background-position: 16.66%, 0;
	}
	66.6% {
		background-position: 50%, 0;
	}
	83.3% {
		background-position: 0%, 0;
	}
}

@keyframes beat {
	0% {
		opacity:  1;
	}
	35% {
		opacity: 1;
	}
	45% {
		opacity: 0.3;
	}
	60% {
		opacity: 1
	}

	100% {
		opacity: 1;
	}
}

@keyframes travel {
	0% {
		background-position: -36rem 0;
	}
	100% {
		background-position: 32rem 0;
	}
}
