:root {
	--bold: 700;
	--black: #000;
	--white: #fff;
	--charcoal: #222;
	--darkCharcoal: #111;
	--lifelineRed: #ad0000;
	--systolic: #0029ff;
	--diastolic: #ff9400;
	--pulse: #34f575;
	--oxygen: #419cdd;
}

*,
:after,
:before {
	box-sizing: border-box;
	user-select: none;
}

html {
	font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
}

body {
	font-size: 1.6rem;
	line-height: 1.25;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ddd;
}

.lifeline {
	width: 100vw;
	max-width: 100vw;
	min-width: 1000px;
	height: 100vh;
	max-height: 100vh;
	min-height: 700px;
}

.hardware {
	float: left;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: transform 1s ease-in-out;
}

.splashImg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(../img/Lifeline_Splash_Single_Logo.gif);
	background-repeat: no-repeat;
	background-color: #ffffff;
	background-size: 100% auto;
	background-position-y: 4em;
	background-position-x: -0.5em;
	z-index: 1000010;
	border-radius: 1.5em;
	opacity: 1;
	transition: opacity 1.5s ease-in-out;
}

.hardware__background {
	background-image: url(../img/hardware-background.png);
	width: 115%;
	height: 130%;
	float: left;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-49%, -50%);
	background-repeat: no-repeat;
	overflow: visible;
	background-size: 100% 100%;
}

.hardware__demo_video {
	width: 100vw;
	height: 100vh;
	z-index: 1000000;
	display: none;
	float: left;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: var(--white);
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
}

.hardware__demo_button {
	background-color: var(--lifelineRed);
	width: 250px;
	height: auto;
	z-index: 1000002;
	position: fixed;
	top: 100%;
	left: calc(100% + 210px);
	transform: translate(-80%, -100%) skew(-10deg);
	border: 0.2em solid;
	border-color: var(--darkCharcoal);
	border-right: unset;
	cursor: pointer;
	font-weight: var(--bold);
	font-size: 2.5rem;
	color: var(--white);
	font-style: italic;
	text-transform: uppercase;
	text-shadow: 0 0 5px var(--charcoal);
	max-width: max-content;
	padding: 0.5em 3.5em 0.5em 1em;
	text-align: center !important;
	transition: left 0.75s ease-in-out;
}

.hardware__demo_button:hover {
	color: var(--lifelineRed);
	background-color: var(--white);
	text-shadow: unset;
	border-color: var(--darkCharcoal);
}

.software {
	border-radius: 1.5em;
	width: 100rem;
	height: 70rem;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	color: var(--white);
}

.header {
	background: var(--charcoal);
	color: var(--white);
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr max-content 1fr;
	align-items: center;
	padding: 0.5rem 2rem;
	font-weight: var(--bold);
	font-size: 2rem;
	min-height: 6rem;
	position: relative;
	z-index: 10;
}

.header__tray {
	display: grid;
	grid-gap: 1.5rem;
	display: flex;
	align-items: center;
}

.header__notifications {
	grid-gap: 1rem;
}

.header__notif {
	position: relative;
	padding: 0 2rem 0 0;
}

.header__dot {
	position: absolute;
	top: -0.5rem;
	right: 0.5rem;
	background: var(--lifelineRed);
	font-size: 1.4rem;
	width: 1.8rem;
	height: 1.8rem;
	display: flex;
	align-items: center;
	line-height: 1;
	justify-content: center;
	transform: skew(-10deg);
	font-style: normal;
}

.header__logo-img {
	display: block;
	max-height: 100%;
	height: 5rem;
}

.header__system {
	justify-content: flex-end;
}

.header__weather,
.header__battery {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: 2rem 1fr;
}

.header__clock-meridiem {
	font-size: 1rem;
	float: right;
	line-height: 90%;
	margin-left: 0.5rem;
	transform: translateY(0.5rem);
}

.header__battery-percent-sign {
	font-size: 1.1rem;
}

.nav__item {
	padding: 0 2rem;
	height: 100%;
	background: #aaa;
	display: flex;
	align-items: center;
	color: #333;
	cursor: pointer;
}

.nav__item:hover {
	background: #ccc;
}

.nav__item--prev {
	grid-area: prev;
}

.nav__item--next {
	justify-content: flex-end;
	grid-area: next;
}

.main {
	position: relative;
	z-index: 20;
	height: 100%;
}

.container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-areas:
		"prev apps next"
		"prev menus next";
	grid-template-columns: 9rem 1fr 9rem;
	grid-template-rows: 1fr max-content;
	align-items: center;
	position: relative;
}

.apps {
	width: 100%;
	background: var(--charcoal);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: italic;
	grid-area: apps;
	position: relative;
	z-index: 10;
}

.menus {
	background: var(--charcoal);
	width: 100%;
	padding: 3rem 9rem;
	grid-area: menus;
}

.menu__buttons {
	display: flex;
	justify-content: center;
	grid-gap: 3rem;
	grid-template-columns: repeat(3, 1fr);
}

.menu__btn,
.modal__btn,
.action__btn,
.add__btn,
.field__button,
.field__radio-lbl {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 1rem;
	padding: 1rem 2.5rem 1rem 2rem;
	border: 0.2rem solid var(--white);
	cursor: pointer;
	transform: skewX(-10deg);
	min-width: 17rem;
	background: 0;
	font-family: inherit;
	color: inherit;
	font-size: 1.6rem;
}

.menu__btn:hover,
.modal__btn:hover,
.action__btn:hover,
.add__btn:hover,
.field__button:hover,
.field__radio-lbl:hover,
.field__radio:checked + .field__radio-lbl {
	color: var(--charcoal);
	background: var(--white);
}

.menu__btn-icon,
.modal__btn-icon,
.action__btn-icon,
.add__btn-icon {
	transform: skewX(10deg);
}

.menu__btn-label,
.modal__btn-label,
.action__btn-label,
.add__btn-label {
	font-weight: var(--bold);
	font-style: italic;
	text-transform: uppercase;
	transform: skewX(10deg) translateY(-1px);
}

.field__button,
.field__radio-lbl {
	font-weight: var(--bold);
	font-style: italic;
	text-transform: uppercase;
	max-width: max-content;
	min-width: 10rem;
	font-size: 1.2rem;
	padding-right: 1.5rem;
	padding-left: 1rem;
}

.field__radio {
	display: none;
}

.menu__drawer {
	background: var(--charcoal);
	width: 40rem;
	box-shadow: 0 1rem 1rem #000;
	position: absolute;
	bottom: 0;
	overflow-y: auto;
	overflow-x: visible;
	transition: 0.25s ease all;
	height: 100%;
	z-index: 20;
}

.menu__drawer::-webkit-scrollbar {
	width: 0.5rem;
	z-index: 20;
}

.menu__drawer::-webkit-scrollbar-track {
	background: var(--charcoal);
}

.menu__drawer::-webkit-scrollbar-thumb {
	background: var(--white);
}

.menu__drawer--emergency {
	left: 0;
	transform: translateX(-105%);
}

.menu__drawer--settings {
	right: 0;
	transform: translateX(105%);
}

.menu__drawer--apps {
	left: 50%;
	transform: translate(-50%, 105%);
}

.menu__drawer--active {
	transform: none;
}

.menu__drawer--apps.menu__drawer--active {
	transform: translateX(-50%);
}

.menu__drawer-title {
	position: relative;
	text-transform: uppercase;
	font-style: italic;
	font-weight: var(--bold);
	margin: 0 0 2rem;
}

.menu__drawer-text {
	font-size: 3rem;
	padding: 2rem 2rem 2rem 7rem;
}

.menu__drawer-icon {
	opacity: 0.1;
	position: absolute;
	left: 2rem;
	top: 1rem;
}

.menu__drawer-close {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(-3rem, -50%);
	cursor: pointer;
}

.menu__drawer-close:hover {
	opacity: 0.5;
}

.main__overlay {
	background: rgba(0, 0, 0, 0.9);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	z-index: 10;
	transition: 0.25s all ease;
	cursor: pointer;
	backdrop-filter: blur(0.75rem);
}

.main__overlay--active {
	opacity: 1;
	visibility: visible;
}

.main__overlay:hover {
	background: rgba(0, 0, 0, 0.95);
}

.menu__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.menu__link {
	background: 0;
	border: 0;
	text-align: left;
	cursor: pointer;
	display: grid;
	width: 100%;
	grid-gap: 1.5rem;
	grid-template-columns: 3rem 1fr;
	padding: 1.5rem 4rem 1.5rem 2rem;
	color: var(--white);
	font-style: italic;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: var(--bold);
	align-items: center;
	font-size: 1.8rem;
	font-family: inherit;
}

.menu__link:hover {
	opacity: 0.5;
}

.menu__link--extra {
	grid-template-columns: 3rem 1fr 2rem;
	padding-right: 2rem;
}

.menu__link-icon {
	display: flex;
	justify-content: center;
}

.menu__drawer--settings .menu__link {
	grid-template-columns: 2rem 1fr;
}

.menu__drawer--settings .menu__link-name {
	order: 1;
}

.menu__item {
	position: relative;
}

.menu__sub {
	background: var(--darkCharcoal);
	top: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	box-shadow: 0 0 1rem var(--black) inset;
	height: 0;
	overflow: hidden;
}

.menu__sub--active {
	height: auto;
}

.menu__sub .menu__link {
	font-size: 1.6rem;
	grid-gap: 1rem;
	padding: 1rem 4rem;
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 20;
	transform: translate(-50%, -50%);
	background: var(--charcoal);
	box-shadow: 0 0 1rem var(--black);
	display: grid;
	grid-gap: 4rem;
	padding: 2rem;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease;
	width: 50%;
}

.modal__header {
	font-weight: var(--bold);
	text-transform: uppercase;
	font-style: italic;
	font-size: 2rem;
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-gap: 2rem;
}

.modal__body {
	text-align: center;
	padding: 1rem;
}

.modal__footer {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 1rem;
}

.modal__cancel {
	border-color: var(--charcoal);
}

.modal__cancel:hover {
	opacity: 0.5;
	background: 0;
	color: var(--white);
}

.modal__close {
	border: 0;
	background: 0;
	padding: 0;
	cursor: pointer;
	color: var(--white);
}

.modal__close:hover {
	opacity: 0.5;
}

.modal__btn {
	padding: 0.75rem 1.5rem 0.75rem 1.25rem;
	min-width: 10rem;
}

.modal--active {
	opacity: 1;
	visibility: visible;
	transform: translate(-50%, calc(-50% + -3rem));
}

.blood-pressure,
.oxygen,
.heart-rate {
	display: grid;
	height: 100%;
	/* grid-gap: 3rem; */
	padding: 3rem 0;
	width: 100%;
}

.chart {
	align-self: flex-end;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: all 0.25s ease;
}

.chart--active {
	height: 30.5rem;
	opacity: 1;
}

.chart-title {
	text-transform: uppercase;
	font-weight: var(--bold);
	padding: 0 0 1rem 3rem;
	opacity: 0.5;
}

.readings {
	font-weight: var(--bold);
	font-size: 2.5rem;
	grid-gap: 4rem;
	padding: 0 3rem;
	align-items: flex-end;
	justify-content: center;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: all 0.25s ease;
	display: flex;
}

.readings--active {
	opacity: 1;
	height: 21rem;
}

.reading {
	width: 100%;
	max-width: 33%;
}

.hr.reading {
	max-width: 50%;
}

.reading .label {
	grid-column: 1 / -1;
	padding: 1rem 2rem;
	transform: skew(-10deg);
	font-style: normal;
	text-transform: uppercase;
	text-shadow: 0 0 5px var(--charcoal);
}

.systolic .label {
	background: var(--systolic);
}

.diastolic .label {
	background: var(--diastolic);
}

.pulse .label {
	background: #444444;
	color: var(--pulse);
}

.spo2 .label {
	background: var(--oxygen);
}

.hr .label {
	background: var(--lifelineRed);
}

.reading .value {
	font-size: 5rem;
}

.reading .value span {
	font-size: 3rem;
}

.actions {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	grid-gap: 3rem;
	flex-wrap: wrap;
}

.measuring {
	font-size: 3rem;
	text-align: center;
	font-weight: var(--bold);
	text-transform: uppercase;
	width: 100%;
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: all 0.25s ease;
}

.measuring svg {
	animation: beat 1s infinite;
	margin-right: 1rem;
}

.measuring--active {
	height: 4rem;
	opacity: 1;
}

.form {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: max-content 1fr;
	align-items: center;
}

.field {
	text-transform: uppercase;
	font-weight: var(--bold);
	font-style: italic;
	display: contents;
}

.field__label {
	opacity: 0.5;
	padding-right: 1rem;
	text-align: right;
}

.field__text,
.field__ddl {
	background: 0;
	color: var(--white);
	border: 0.2rem solid var(--white);
	padding: 0.5rem 1rem;
	font-family: inherit;
	text-transform: uppercase;
	font-weight: var(--bold);
	font-size: 1.8rem;
	transform: skew(-10deg);
	outline: 0;
}

.field__text:focus {
	box-shadow: 0 0 1rem inset var(--black);
}

.field__check {
	margin-left: auto;
	background: 0;
	border: 0.2rem solid var(--white);
	transform: skew(-10deg);
	width: 2rem;
	height: 2rem;
	appearance: none;
	cursor: pointer;
}

.field__check:hover {
	opacity: 0.5;
}

.field__check:checked {
	background: var(--lifelineRed);
}

.field__check + .field__label,
.field__radio + .field__label {
	text-align: left;
}

.field__value {
	text-align: left;
}

.field__radio-opt {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1rem;
}

.add__btn {
	margin: 2rem auto;
}

.calendar {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-columns: max-content 1fr;
}

.calendar-actual {
	padding: 2rem 4rem;
	text-transform: uppercase;
	font-weight: var(--bold);
	position: relative;
	z-index: 10;
}

.calendar-month {
	text-align: center;
	font-size: 2.4rem;
	padding: 0 0 2rem;
}

.calendar-days {
	display: grid;
	grid-gap: 1.5rem;
}

.days-body {
	display: grid;
	grid-gap: 1.5rem;
}

.days-row {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: repeat(7, 1fr);
}

.days-cell {
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.days-cell--invalid {
	opacity: 0.1;
}

.days-button {
	border: 0;
	background: 0;
	font-family: inherit;
	color: var(--white);
	font-weight: var(--bold);
	font-size: inherit;
	cursor: pointer;
	line-height: 1;
	width: 3.6rem;
	height: 3.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: skew(-10deg);
}

.days-button--empty {
	background: 0;
}

.days-button:hover {
	opacity: 0.5;
}

.days-button--today {
	background: rgba(0, 0, 0, 0.5);
}

.days-button--active {
	background: var(--lifelineRed);
	font-size: 2rem;
}

.calendar-selected {
	padding: 1rem 2rem;
	background: var(--lifelineRed);
	transform: skew(-10deg);
	font-style: normal;
	font-size: 2rem;
	display: flex;
	max-width: max-content;
	margin: 2rem auto 0;
	position: relative;
}

.calendar-selected:after {
	content: "";
	position: absolute;
	width: 20rem;
	height: 0.5rem;
	background: linear-gradient(90deg, var(--lifelineRed) 0%, var(--lifelineRed) 25%, var(--ash) 50%);
	top: 50%;
	transform: translateY(-50%);
}

.calendar-selected:before {
	right: 95%;
}

.calendar-selected:after {
	left: 95%;
}

.calendar-panel {
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	border-left: 0.5rem solid var(--ash);
	background: var(--ash);
	grid-gap: 0.5rem;
	position: relative;
	z-index: 20;
}

.panel-panel {
	background: var(--charcoal);
}

.panel-title {
	position: relative;
	font-weight: var(--bold);
	font-size: 2rem;
	text-transform: uppercase;
	padding: 2rem 1rem 2rem 6rem;
}

.panel-icon {
	opacity: 0.1;
	position: absolute;
	top: 50%;
	left: 2rem;
	transform: translateY(-50%);
}

.panel-content ul {
	font-style: italic;
	display: grid;
	grid-gap: 0.5rem;
	list-style-type: square;
	font-size: 1.4rem;
	font-style: normal;
}

.notify {
	position: absolute;
	top: 5rem;
	left: 50%;
	background: rgba(34, 34, 34, 0.9);
	backdrop-filter: blur(0.5rem);
	width: calc(100% - 10rem);
	z-index: 15;
	box-shadow: 0 0 1rem var(--black);
	transform: translate(-50%, 0);
	/* skew(-10deg) */
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease;
	margin-top: -25rem;
}

.notify--simple {
	animation-name: notifyPulse;
	animation-timing-function: ease-in-out;
	animation-fill-mode: both;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

@keyframes notifyPulse {
	from {
		transform: translateX(-50%) scale3d(1, 1, 1);
	}

	50% {
		transform: translateX(-50%) scale3d(1.05, 1.05, 1.05);
	}

	to {
		transform: translateX(-50%) scale3d(1, 1, 1);
	}
}

.notify--active {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
}

.notify + .notify {
	top: 20rem;
}

.notify + .notify + .notify {
	top: 35rem;
}

.notify + .notify + .notify + .notify {
	top: 50rem;
}

.notify__icon {
	padding: 2rem 3rem;
	display: flex;
	align-items: center;
}

.notify__body {
	padding: 2rem;
	font-size: 1.8rem;
	display: grid;
	width: 100%;
	height: 100%;
	align-items: center;
	grid-gap: 1rem;
	/* border-width: 0 1rem;
	border-color: var(--white);
	border-style: solid; */
}

.notify__header {
	font-size: 2.4rem;
	font-weight: var(--bold);
	font-style: italic;
	text-transform: uppercase;
}

.notify__close {
	padding: 2rem;
}

.notify__close-btn {
	background: 0;
	border: 0;
	color: var(--white);
	padding: 0;
	cursor: pointer;
}

.notify__close-btn:hover {
	opacity: 0.5;
}

.notifications-fake {
	position: absolute;
	top: -6rem;
	left: 0;
	background: 0;
	width: 18rem;
	height: 6rem;
	z-index: 15;
}

.notifications-fake:hover {
	cursor: pointer;
	background: rgba(34, 34, 34, 0.5);
}

.notification-center .notify {
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease;
	transform: translate(-50%, 10rem);
}

.notification-center--active .notify {
	opacity: 1;
	visibility: visible;
	transform: translate(-50%, 0);
}

.test__btn {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}
